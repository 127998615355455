import {
  Box,
  Grid,
  Card,
  Typography,
  CircularProgress,
  Button,
  Hidden
} from "@material-ui/core";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../Context/Context";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import EventIcon from "@material-ui/icons/Event";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  text: {
    justifyContent: "left",
  },
  face: {
    background: "#1778F2",
    color: "white",
    borderRadius: "25px",
  },
  twitter: {
    background: "#1DA1F2",
    color: "white",
    borderRadius: "25px",
  },
  fake: {
    height: "50px",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Event = () => {
  const { events, loadingEvents } = useContext(Context);
  
  const { eventId } = useParams();
  const event = events.find((event) => event.id === eventId);
  const url = window.location.href;

  const classes = useStyles();

 

  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Eventos de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Hidden smDown>
        <Box className={classes.fake} zIndex="modal"></Box>
      </Hidden>
      <Box mx="10%" mt="5%" className={classes.root}>
        {loadingEvents ? (
          <CircularProgress />
        ) : (
          <Box>
            <Grid container direction="column" alignItems="center" spacing={5}>
              <Grid item className={classes.text} xs={12} sm={8} md={8} lg={10}>
                <Typography variant="h4">
                  <Box fontWeight="fontWeightBold">{event.nombre}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={5}>
                <Card>
                  <CardMedia
                    className={classes.image}
                    component="img"
                    alt="La Matriz Radio"
                    image={process.env.REACT_APP_STRAPI_URL + event.imagen.url}
                  />
                </Card>
              </Grid>
              <Box ml="25%">
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={8}
                >
                  <Grid item container direction="row" spacing={2}>
                    <Grid item>
                      <EventIcon></EventIcon>
                    </Grid>
                    <Grid item>
                      <Typography>
                        <Box fontWeight="fontWeightBold">{event.fecha}</Box>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.text}>
                    <ReactMarkdown source={event.descripcion} />
                  </Grid>
                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item>
                      <Box>
                        <FacebookShareButton size={32} round={false} url={url}>
                          <Button size="medium" className={classes.face}>
                            <FacebookIcon fontSize="medium"></FacebookIcon>
                            <Box>compartir</Box>
                          </Button>
                        </FacebookShareButton>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box my={7}>
                        <TwitterShareButton size={32} round={false} url={url}>
                          <Button size="medium" className={classes.twitter}>
                            <TwitterIcon fontSize="medium"></TwitterIcon>
                            <Box>compartir</Box>
                          </Button>
                        </TwitterShareButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Event;
