import React from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HostRigth from "../resources/HostRigth";
import HostLeft from "../resources/HostLeft";
const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  text: {
    justifyContent: "left",
  },
  fake: {
    height: "50px",
  },
  image: {
    height: "150px",
  },
  top: {
    background: "#292929",
    color: "white",
  },
  titulo: {
    color: "#5534BE",
  },
  conductores: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
  },
  button: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
    borderRadius: "25px",
  },
});

const Desarrollo = () => {
  const classes = useStyles();
  let Programadores = [
    {
      nombre: "Alvaro Andrade",
      descripcion:
        "Encargado de desarrollo - Analista - Desarrollador Frontend -  Desarrollador responsive",
      contacto: "arvaloplz@gmail.com",
      foto: {
        url: "/uploads/icono3_5d56982391.png",
      },
    },
    {
      nombre: "Sergio Latorre",
      descripcion:
        "Encargado de desarrollo - Analista - Desarrollador Frontend -  Desarrollador responsive",
      contacto: "sergio.latorrecaballero.96@gmail.com",
      foto: {
        url: "/uploads/icono3_5d56982391.png",
      },
    },
  ];
  let Diseñadoras = [
    {
      nombre: "Valentina Zapata",
      descripcion:
        "Encargada de diseño - Diseño de marca - Diseño de prototipo de la página web",
      contacto: "visabel_zv@hotmail.com",
      foto: {
        url: "/uploads/icono1_ad2f932e0b.png",
      },
    },
    {
      nombre: "Danyela Peralta",
      descripcion:
        "Encargada de diseño - Diseño de marca - Diseño de prototipo de la página web",
      contacto: "danyela.peraltat@gmail.com",
      foto: {
        url: "/uploads/icono1_ad2f932e0b.png",
      },
    },
  ];
  let Organizadores = [
    {
      nombre: "Romina Cabrera",
      descripcion: "Coordinadora de la corporación La Matriz",
      contacto: "romina.cabrerag@gmail.com ",
      foto: {
        url: "/uploads/icono_2_62ee1e8370.png",
      },
    },
    {
      nombre: "Nicole Arredondo",
      descripcion: "Coordinadora de la corporación La Matriz",
      contacto: "kine.naaf@gmail.com ",
      foto: {
        url: "/uploads/icono_2_62ee1e8370.png",
      },
    },
    {
      nombre: "Luis Vásquez ",
      descripcion: "Coordinador de La Matriz Radio",
      contacto: "luisvasquezqr@gmail.com",
      foto: {
        url: "/uploads/icono_2_62ee1e8370.png",
      },
    },
    {
      nombre: "Pedro Escudero",
      descripcion: "Coordinador de La Matriz Radio",
      contacto: "escudero.p77@gmail.com",
      foto: {
        url: "/uploads/icono_2_62ee1e8370.png",
      },
    },
    {
      nombre: "Paula Bustamante",
      descripcion: "Coordinadora de PUCV en el desarrollo del proyecto",
      contacto: "paula.bustamante@pucv.cl",
      foto: {
        url: "/uploads/icono_2_62ee1e8370.png",
      },
    },
    {
      nombre: "Wenceslao Palma",
      descripcion: "Coordinador de PUCV en el desarrollo del proyecto",
      contacto: "wenceslao.palma@pucv.cl",
      foto: {
        url: "/uploads/icono_2_62ee1e8370.png",
      },
    },
  ];
  const isPar = (position) => {
    let r;
    position % 2 ? (r = true) : (r = false);
    return r;
  };

  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Desarrollo de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Box className={classes.image} zIndex="modal"></Box>

      <Box>
        <Box className={classes.top} mb={-4}>
          <Container>
            <Grid container direction="row" spacing={10}>
              <Grid container item xs={12} sm={6} md={4} lg={5} xl={5}>
                <Box>
                  <Grid
                    item
                    container
                    direction="column"
                    wrap="nowrap"
                    justify="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h4">
                        <Box fontWeight="fontWeightBold">Créditos</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box p={5}>
              <Grid
                container
                item
                direction="row"
                md={12}
                justify="center"
                alignItems="center"
              >
                <Grid item md={4}>
                  <Box pl={10}>
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_URL +
                        "/uploads/cropped_logo512_14_3732f6939b.png?1424139.199999976"
                      }
                      alt="la matriz radio"
                      width="40%"
                    />
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box>
                    <img
                      src={require("../../assets/marca-la-matriz-radio-1.png")}
                      alt="la matriz radio"
                      width="90%"
                    />
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box>
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_URL +
                        "/uploads/Escuela_Ingenieria_Informatica_PUCV_Blanco_c7952b3ddd.png?573169.899999979"
                      }
                      alt="la matriz radio"
                      width="90%"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box mt={3} className={classes.conductor}>
          <Box py={3} className={classes.conductores}>
            <Container>
              <Typography variant="h4">
                <Box my={5} fontWeight="fontWeightBold">
                  {"Diseño"}
                  {Diseñadoras.map((host, i) =>
                    isPar(i) ? (
                      <HostLeft key={i} host={host}></HostLeft>
                    ) : (
                      <HostRigth key={i} host={host}></HostRigth>
                    )
                  )}
                </Box>
                <Box my={5} fontWeight="fontWeightBold">
                  {"Desarrollo"}
                  {Programadores.map((host, i) =>
                    isPar(i) ? (
                      <HostLeft key={i} host={host}></HostLeft>
                    ) : (
                      <HostRigth key={i} host={host}></HostRigth>
                    )
                  )}
                </Box>
                <Box my={5} fontWeight="fontWeightBold">
                  {"Coordinación"}
                  {Organizadores.map((host, i) =>
                    isPar(i) ? (
                      <HostLeft key={i} host={host}></HostLeft>
                    ) : (
                      <HostRigth key={i} host={host}></HostRigth>
                    )
                  )}
                </Box>
              </Typography>
            </Container>
            {/* {programa.conductores.map((host, i) =>      HACER UN MODELO DE DATOS CON UN NUEVO HOST
                  isPar(i) ? (
                    <HostLeft key={i} host={host}></HostLeft>
                  ) : (
                    <HostRigth key={i} host={host}></HostRigth>
                  )
                )} */}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default Desarrollo;
