import React, { useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Card,
  Typography,
  CircularProgress,
  Container,
  Hidden,
  Chip,
} from "@material-ui/core";
import { Context } from "../../Context/Context";
import { makeStyles } from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgramPhoto from "../resources/ProgramPhoto";
import "video-react/dist/video-react.css";
import { Player, ControlBar } from "video-react";
import HostRigth from "../resources/HostRigth";
import HostLeft from "../resources/HostLeft";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  text: {
    justifyContent: "left",
    marginRight: 20,
  },
  fake: {
    height: "50px",
  },
  image: {
    height: "150px",
  },
  galeria: {
    background: "#292929",
    color: "white",
  },
  titulo: {
    color: "#5534BE",
  },
  conductores: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
  },
  button: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
    borderRadius: "25px",
  },
});

const Program = () => {
  const { programs, loadingPrograms } = useContext(Context);
  const { programaId } = useParams();
  const programa = programs.find((programa) => programa.id === programaId);
  const classes = useStyles();

  if (!loadingPrograms && !programa) {
    return <Redirect to="/" />;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const isPar = (position) => {
    let r;
    position % 2 ? (r = true) : (r = false);
    return r;
  };

  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Programa de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Hidden smDown>
        <Box className={classes.image} zIndex="modal"></Box>
      </Hidden>

      <Box>
        {loadingPrograms ? (
          <CircularProgress />
        ) : (
          <Box>
            <Box mb={10} mt={10}>
              <Container>
                <Grid container direction="row">
                  <Grid item xs={12} sm={6} md={4} lg={5} xl={5}>
                    <Box ml={2}>
                      <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        justify="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="h4">
                            <Box
                              fontWeight="fontWeightBold"
                              className={classes.titulo}
                            >
                              {programa.nombre}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                          <Typography>{`Horario: ${programa.Horario}`}</Typography>
                        </Grid>
                        <Grid item className={classes.text}>
                          <ReactMarkdown source={programa.descripcion} />
                        </Grid>
                      </Grid>
                      <Box my={3}>
                        <Button
                          className={classes.button}
                          href="https://www.facebook.com/La-Matriz-Radio-2147581975527960/videos"
                          target="_blank"
                        >
                          <VideoLibraryIcon fontSize="large"></VideoLibraryIcon>
                          <Box mx={1}>Nuestro contenido </Box>
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={5}>
                    <Card>
                      <Player
                        playsInline
                        src={
                          process.env.REACT_APP_STRAPI_URL + programa.video.url
                        }
                      >
                        <ControlBar disableCompletely={true} />
                      </Player>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box mt={3} className={classes.conductor}>
              {programa.conductores.length >= 1 ? (
                <Box py={3} className={classes.conductores}>
                  <Container>
                    <Typography variant="h4">
                      <Box my={5} fontWeight="fontWeightBold">
                        {"Conductores"}
                      </Box>
                    </Typography>
                  </Container>
                  {programa.conductores.map((host, i) =>
                    isPar(i) ? (
                      <HostLeft key={i} host={host}></HostLeft>
                    ) : (
                      <HostRigth key={i} host={host}></HostRigth>
                    )
                  )}
                </Box>
              ) : programa.conductores.length > 0 ? (
                <Box className={classes.conductores}>
                  <Typography variant="h4">
                    <Box my={3} fontWeight="fontWeightBold">
                      {"Conductor"}
                    </Box>
                  </Typography>
                </Box>
              ) : (
                <Container>
                  <Box textAlign="left" my={7} fontWeight="fontWeightBold">
                    <Chip size="medium" label="NUESTRO CONDUCTOR NO ESTA DEFINIDO " color="primary" />
                  </Box>
                </Container>
              )}
            </Box>
            {programa.fotos.length === 0 ? (
              <div></div>
            ) : (
              <>
                <Hidden smDown>
                  <Box className={classes.galeria}>
                    <Box p={5} mx="10%">
                      <Typography variant="h4">
                        <Box
                          fontWeight="fontWeightBold"
                          className={classes.text}
                        >
                          {"Galería"}
                        </Box>
                      </Typography>
                      <Slider {...settings}>
                        {programa.fotos.map((foto, i) => (
                          <ProgramPhoto foto={foto} key={i} />
                        ))}
                      </Slider>
                    </Box>
                  </Box>
                </Hidden>

                <Hidden mdUp>
                  <Box className={classes.galeria}>
                    <Box>
                      <Typography variant="h4">
                        <Box
                          fontWeight="fontWeightBold"
                          p={3}
                          className={classes.text}
                        >
                          {"Galería"}
                        </Box>
                      </Typography>
                      <Slider {...settings}>
                        {programa.fotos.map((foto, i) => (
                          <ProgramPhoto foto={foto} key={i} />
                        ))}
                      </Slider>
                    </Box>
                  </Box>
                </Hidden>
              </>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Program;
