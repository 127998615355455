import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  image:{
    borderRadius: "50%",
    boxShadow: "0 3px 30px 20px #00000029"
  }
});

function HostLeft({ host }) {
  const classes = useStyles();
  return (
    <Box my={5}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={4}
          lg={6}
          xl={6}
          justify="flex-end"
        >
          <img
            height={250}
            alt="La Matriz Radio"
            src={process.env.REACT_APP_STRAPI_URL + host.foto.url}
            className={classes.image}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={6}
          md={4}
          lg={6}
          xl={6}
        >
          <Typography variant="h5">
            <Box ml={10} mt={9} fontWeight="fontWeightBold">
              {" "}
              {host.nombre}{" "}
            </Box>
          </Typography>
          {host.descripcion ? (
            <Typography variant="body1">
              <Box ml={10} m={3} mt={3}>
                {" "}
                {host.descripcion}{" "}
              </Box>
            </Typography>
          ) : (
            <div></div>
          )}
          {host.contacto ? (
            <Typography component={"span"} variant="body1">
              <Box m={3} ml={10} mt={3}>
                {" "}
                {"Contacto : " + host.contacto}{" "}
              </Box>
            </Typography>
          ) : (
            <Box m={3} ml={10} mt={3}>
              {" "}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default HostLeft;
