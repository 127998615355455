import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Pag = ({itemsPerPage, totalItems, paginate}) => {
  const classes = useStyles();
  const pages = Math.ceil(totalItems/itemsPerPage);
  const changePage = (event, value) => {
    paginate(value);
  };

  return (
    <Box className={classes.root}>
      <Pagination count={pages} onChange={changePage} shape="rounded" color="primary"/>
    </Box>
  );
};

export default Pag;
