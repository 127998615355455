import React from "react";
import { Box, Card, CardMedia } from "@material-ui/core";



const ProgramPhoto = ({ foto }) => {
  return (
    <Box m={3}>
      <Card>
        <CardMedia
          height="240"
          component="img"
          alt="La Matriz Radio"
          image={process.env.REACT_APP_STRAPI_URL + foto.url}
        />
      </Card>
    </Box>
  );
};

export default ProgramPhoto;
