import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Hidden,
  Button,
  Container,
} from "@material-ui/core";
import { ShowGrid } from "../resources/ShowGrid";
import { Chip } from "@material-ui/core";
import { ShowGridTop } from "../resources/ShowGridTop";
import { Context } from "../../Context/Context";
import HomeIcon from "@material-ui/icons/Home";
import MeCard from "../resources/MeCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ArrowStyle.css";
import principalImage from "../../assets/port1.jpg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    marginBottom: 410,
  },
  imageDesktop: {
    width: "100%",
    maxHeight: "900px",
  },
  imageDosDesktop: {
    maxHeight: 900,
    width: "80%",
  },
  imageMobile: {
    width: "100%",
    background: "#BABABA",
  },
  twitter: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
    borderRadius: "25px",
  },
  importantContent: {
    background:
      "transparent linear-gradient(180deg, #5735BF 0%, #432CBC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    color: "white",
    padding: 27,
  },
  slider: {
    background: "#F0F0F0",
    padding: 27,
    paddingBottom: 50,
  },
  imagenSlider: {
    height: 500,
    width: "100%",
  },
  player: {
    background: "#292929",
  },
  program: {
    textShadow: "2px 0px 30px #ffffff;",
  },
  frontImage: {
    backgroundImage: `url(${principalImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
});

const Inicio = () => {
  const {
    data,
    loading,
    programs,
    loadingPrograms,
    events,
    loadingEvents,
    adds,
    loadingAdd,
  } = useContext(Context);
  const newsPortada = data.filter((noticia) => noticia.portada === true);
  const eventsPortada = events.filter((events) => events.destacado === true);
  const pathNews = "/noticias/";
  const pathPrograms = "/programas/";
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const settingsAdd = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplaySpeed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const classes = useStyles();
  return (
    <div>
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="pagina de inicio de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Hidden smDown>
        <Box>
          <Box className={classes.frontImage}>
            <img
              className={classes.imageDosDesktop}
              alt="la Matriz Radio"
              src={require("../../assets/fig-portada.png")}
            />
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box>
          <Grid container justify="center">
            <img
              src={require("../../assets/marca-la-matriz-radio-1.png")}
              alt="la matriz radio"
              width="55%"
            />
          </Grid>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Container>
          <Hidden mdUp>
            <Box mt="55%"></Box>
          </Hidden>
          <Box className={classes.root} zIndex="tooltip" mt="-55%">
            <Hidden smDown>
              <Grid container className={classes.gridContainer} direction="row">
                <Grid item sm={7} md={4}>
                  <Typography variant="h3">
                    <Box
                      textAlign="left"
                      fontWeight="fontWeightBold"
                      style={{ color: "#432CBC" }}
                    >
                      La Matriz Radio
                    </Box>
                  </Typography>
                  <Typography
                    component={"span"}
                    variant="h5"
                    fontWeight="fontWeightBold"
                  >
                    <Box my={0} textAlign="left" style={{ color: "#5534BE" }}>
                      Hacemos comunidad !
                    </Box>
                  </Typography>
                  <Box mt={3}>
                    <Box ml={-1}>
                      <Button
                        size="medium"
                        className={classes.twitter}
                        href="https://corporacionlamatriz.cl/"
                        target="_blank"
                        rel="noopener"
                      >
                        <HomeIcon fontSize="large"></HomeIcon>
                        <Box mx={1}>visita a la corporación </Box>
                      </Button>
                    </Box>
                    <Typography component={"span"} variant="body1">
                      <Box my={4} textAlign="left">
                        Busca nuestra aplicación disponible para IOS y Android,
                        con más contenido de la radio .
                      </Box>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
          </Box>
        </Container>
      </Hidden>
      <Grid container className={classes.slider} direction="row">
        <Container>
          <Typography variant="h4" className={classes.program}>
            <Box textAlign="left" mt={7} fontWeight="fontWeightBold">
              Programas la Matriz Radio
            </Box>
          </Typography>

          {loadingPrograms ? (
            <CircularProgress />
          ) : programs.length > 0 ? (
            <Slider {...settings}>
              {programs.map((program, i) => (
                <MeCard data={program} path={pathPrograms} key={i} m={3} />
              ))}
            </Slider>
          ) : (
            <Box textAlign="left" mt={7} fontWeight="fontWeightBold">
              <Chip size="medium" label="PRONTO" color="primary" />
            </Box>
          )}
        </Container>
      </Grid>
      <Grid container className={classes.importantContent} direction="row">
        <Container>
          <Grid container className={classes.gridContainer} direction="row">
            <Grid item sm={12}>
              <Typography variant="h4">
                <Box textAlign="left" fontWeight="fontWeightBold" mt={8} mb={5}>
                  Noticias en la Matriz Radio
                </Box>
              </Typography>
            </Grid>
            <Grid item container>
              {loading ? (
                <CircularProgress />
              ) : newsPortada.length > 0 ? (
                <ShowGrid data={newsPortada} path={pathNews} />
              ) : (
                <Box textAlign="left" mt={4} fontWeight="fontWeightBold">
                  <Chip size="medium" label="PRONTO" color="primary" />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container className={classes.slider} direction="row">
        <Container>
          {loadingAdd ? (
            <CircularProgress />
          ) : adds.length > 0 ? (
            <Box>
              <Hidden xsDown>
                <Slider {...settingsAdd}>
                  {adds.map((add, i) => (
                    <img
                      className={classes.imagenSlider}
                      key={i}
                      alt="la Matriz Radio"
                      src={
                        process.env.REACT_APP_STRAPI_URL +
                        add.imagen_escritorio.url
                      }
                    />
                  ))}
                </Slider>
              </Hidden>
              <Hidden smUp>
                <Slider {...settingsAdd}>
                  {adds.map((add, i) => (
                    <img
                      key={i}
                      alt="la Matriz Radio"
                      src={
                        process.env.REACT_APP_STRAPI_URL + add.imagen_movil.url
                      }
                    />
                  ))}
                </Slider>
              </Hidden>
            </Box>
          ) : (
            <Box textAlign="left" mt={4} fontWeight="fontWeightBold">
              <Chip
                size="medium"
                label="ANUNCIA CON NOSOTROS"
                color="primary"
              />
            </Box>
          )}
        </Container>
      </Grid>
      <Grid container className={classes.importantContent} direction="row">
        <Container>
          <Box my={5}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4">
                <Box textAlign="left" fontWeight="fontWeightBold" mt={8}>
                  Eventos Destacados
                </Box>
              </Typography>
            </Grid>
            <Grid item container>
              {loadingEvents ? (
                <CircularProgress />
              ) : eventsPortada.length > 0 ? (
                <ShowGridTop data={eventsPortada} />
              ) : (
                <Box textAlign="left" mt={4} fontWeight="fontWeightBold">
                  <Chip size="medium" label="PRONTO" color="primary" />
                </Box>
              )}
            </Grid>
          </Box>
        </Container>
      </Grid>
    </div>
  );
};

export default Inicio;
