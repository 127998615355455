import React, { useState, useContext } from "react";
import {
  CircularProgress,
  Typography,
  Box,
  Container,
  Grid,
  Hidden,
} from "@material-ui/core";
import { ShowGrid } from "../resources/ShowGrid";
import { Chip } from "@material-ui/core";
import Pag from "../resources/Pag";
import { Context } from "../../Context/Context";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  image: {
    height: "50px",
  },
  pageController: {
    marginTop: '200px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '50px',
  },
});

const News = () => {
  const { data, loading } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const path = "/noticias/";
  const classes = useStyles();

  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Noticias de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Hidden smDown>
        <Box my={5} className={classes.image} zIndex="modal"></Box>
      </Hidden>

      <Hidden smUp>
        <Grid container justify="center">
          <img
            src={require("../../assets/marca-la-matriz-radio-1.png")}
            alt="la matriz radio"
            width="55%"
          />
        </Grid>
      </Hidden>

      <Box mb={10}>
        <Grid container justify="center" direction="column">
          <Grid item xs={12} sm={12}>
            <Container>
              <Typography variant="h4">
                <Box textAlign="left" fontWeight="fontWeightBold" my={5}>
                  Noticias en la Matriz Radio
                </Box>
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : currentItems.length > 0 ? (
                <ShowGrid data={currentItems} path={path} />
              ) : (
                <Box textAlign="left" mt={4} fontWeight="fontWeightBold">
                  <Chip size="medium" label="PRONTO" color="primary" />
                </Box>
              )}
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.pageController}>
        <Pag
          itemsPerPage={itemsPerPage}
          totalItems={data.length}
          paginate={paginate}
        />
      </Box>
    </div>
  );
};

export default News;
