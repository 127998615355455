import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { Grid, Box, Hidden } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  grow: {
    marginLeft: "auto",
  },
  nav: {
    backgroundColor: "rgba(0, 0, 0, 0) ",
    boxShadow: "none",
  },
  navSolid: {
    backgroundColor: "#ffffff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    color: "black",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    borderStyle: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    borderStyle: "none",
    textTransform: "none",
    margin: 2,
  },
  buttonPhone: {
    color:"black"
  },
}));

export default function Navbar() {
  const classes = useStyles();
  //const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const [navBackground, setNavBackground] = useState("nav");
  const navRef = React.useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 120;
      if (show) {
        // if is descktop
        setNavBackground("navSolid");
      } else {
        setNavBackground("nav");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: "black",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: "none",

      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "black",
        opacity: 1,
      },
      "&$selected": {
        color: "black",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <Button
            component={NavLink}
            className={classes.button}
            exact
            to="/"
            onClick={handleMobileMenuClose}
            variant="outlined"
            fullWidth
          >
            Inicio
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={NavLink}
            exact
            to="/noticias"
            onClick={handleMobileMenuClose}
            variant="outlined"
            fullWidth
            className={classes.button}
          >
            Noticias
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={NavLink}
            exact
            to="/programas"
            onClick={handleMobileMenuClose}
            variant="outlined"
            fullWidth
            className={classes.button}
          >
            Programas
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={NavLink}
            exact
            to="/somos"
            onClick={handleMobileMenuClose}
            variant="outlined"
            fullWidth
            className={classes.button}
          >
            Quienes Somos
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            component={NavLink}
            exact
            to="/contacto"
            onClick={handleMobileMenuClose}
            variant="outlined"
            fullWidth
            className={classes.button}
          >
            Contacto
          </Button>
        </MenuItem>
        <IconButton
          href="https://www.facebook.com/La-Matriz-Radio-2147581975527960/"
          target="_blank" rel="noopener"
        >
          <FacebookIcon style={{ fontSize: 30 }} />
        </IconButton>
        <IconButton href="https://twitter.com/LaMatrizRadio" target="_blank" rel="noopener">
          <TwitterIcon style={{ fontSize: 30 }} />
        </IconButton>
        <IconButton
          href="https://www.youtube.com/channel/UCdKcdF3x23geD-w_9dmyAnw"
          target="_blank" rel="noopener"
        >
          <YouTubeIcon style={{ fontSize: 30 }} />
        </IconButton>
        <IconButton
          href="https://www.instagram.com/lamatrizradio/"
          target="_blank" rel="noopener"
        >
          <InstagramIcon style={{ fontSize: 30 }} />
        </IconButton>
      </Menu>
    </Box>
  );

  return (
    <Grid>
      <Hidden smDown>
        <Box zIndex="tooltip" position="fixed">
          <AppBar className={classes[navRef.current]}>
            <Toolbar>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={4}>
                  <Box ml="28%" mt={1}>
                    <a href="/">
                      <img
                        src={require("../../assets/marca-la-matriz-radio-1.png")}
                        alt="la matriz radio"
                        width="45%"
                      />
                    </a>
                  </Box>
                </Grid>

                <Grid item sm={8}>
                  <Box className={classes.sectionDesktop} ml={3}>
                    <StyledTabs
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="appbar"
                    >
                      <StyledTab
                        label="Inicio"
                        component={NavLink}
                        className={classes.button}
                        exact
                        to="/"
                        style={{ minWidth: "10%" }}
                      />
                      <StyledTab
                        label="Noticias"
                        component={NavLink}
                        className={classes.button}
                        exact
                        to="/noticias"
                        style={{ minWidth: "10%" }}
                      />
                      <StyledTab
                        label="Programas"
                        component={NavLink}
                        className={classes.button}
                        exact
                        to="/programas"
                        style={{ minWidth: "10%" }}
                      />
                      <StyledTab
                        label="Somos"
                        component={NavLink}
                        className={classes.button}
                        exact
                        to="/somos"
                        style={{ minWidth: "9%" }}
                      />
                      <StyledTab
                        label="Contacto"
                        component={NavLink}
                        className={classes.button}
                        exact
                        to="/contacto"
                        style={{ minWidth: "10%" }}
                      />
                    </StyledTabs>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </Hidden>
      <Box className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          className={classes.buttonPhone}
        >
          <MoreIcon />
        </IconButton>
        {renderMobileMenu}
      </Box>
    </Grid>
  );
}
