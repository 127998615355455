import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  image: {
    borderRadius: "50%",
    boxShadow: "0 3px 30px 20px #00000029",
  },
});

function HostRigth({host}) {
    const classes = useStyles();
  return (
    <Box my={7}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={6}
          md={4}
          lg={6}
          xl={6}
          justify="center"
        >
          <Typography variant="h5">
            <Box textAlign="right" mr={10} mt={9} fontWeight="fontWeightBold">
              {" "}
              {host.nombre}{" "}
            </Box>
          </Typography>
          {host.descripcion ? (
            <Typography variant="body1">
              <Box textAlign="right" mr={10} m={3} mt={3}>
                {" "}
                {host.descripcion}{" "}
              </Box>
            </Typography>
          ) : (
            <div></div>
          )}
          {host.contacto ? (
            <Typography component={"span"} variant="body1">
              <Box textAlign="right" m={3} mr={10} mt={3}>
                {" "}
                {"Contacto : " + host.contacto}{" "}
              </Box>
            </Typography>
          ) : (
            <Box m={3} ml={10} mt={3}>
              {" "}
            </Box>
          )}
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          xs={12}
          sm={6}
          md={4}
          lg={6}
          xl={6}
        >
          <img
            height={250}
            alt="Matriz Radio"
            src={process.env.REACT_APP_STRAPI_URL + host.foto.url}
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HostRigth;
