import React from "react";
import {
  Card,
  Box,
  CardContent,
  Typography,
  CardMedia
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: "25px",
    boxShadow: "0 3px 30px 0 #00000029",
  },
});

function MeCard({ data, path, m }) {
  const classes = useStyles();
  if(!m){
    m = 0;
  }

  return (
    <>
      <Box m={m}>
        <Card className={classes.root}>
          {data.imagen ? (
            <Box key={data.imagen.name}>
              {path ? (
                <Link to={`${path}${data.id}`}>
                  <CardMedia
                    component="img"
                    alt={"La Matriz Radio"}
                    image={process.env.REACT_APP_STRAPI_URL + data.imagen.url}
                    height="100%"
                  />
                </Link>
              ) : (
                <CardMedia
                  component="img"
                  alt={"La Matriz Radio"}
                  image={process.env.REACT_APP_STRAPI_URL + data.imagen.url}
                  height="100%"
                />
              )}
            </Box>
          ) : (
            ""
          )}
          <CardContent m={5} className={classes.cardContent}>
            <Typography component="div">
              <Box
                fontSize="90%"
                fontWeight="fontWeightBold"
                textAlign="center"
                m={1}
              >
                {data.titulo || data.nombre}
              </Box>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default MeCard;
