import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  // Button,
  // FormControl,
  // Input,
  // InputLabel,
  // FormHelperText,
  Hidden,
} from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
// import SendIcon from "@material-ui/icons/Send";
// import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  image: {
    height: "50px",
  },
  send: {
    background: "#432CBC",
    color: "white",
    borderRadius: "25px",
  },
  box: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
    borderRadius: "15px",
  },
}));
const Contact = () => {
  const [mensage, setMensage] = useState({
    client: "",
    email: "",
    phone: "",
    subject: "",
    mesage: "",
    clientError: "",
    emailError: "",
    phoneError: "",
    subjectError: "",
    mesageError: "",
  });
  const [cap, setCap] = useState(false);

  // function onChange(value) {
  //   if (value) {
  //     setCap(true);
  //   }
  // }
  const handleChange = (e) => {
    if (e.target.name === "email") {
      let errorEmail = "";
      if (!e.target.value.includes("@")) {
        errorEmail = "Email invalido";
        setMensage({
          ...mensage,
          [e.target.name]: e.target.value,
          emailError: errorEmail,
        });
      } else {
        setMensage({
          ...mensage,
          [e.target.name]: e.target.value,
          emailError: "",
        });
      }
    } else {
      if (e.target.name === "phone") {
        setMensage({
          ...mensage,
          [e.target.name]: e.target.value,
          phoneError: "",
        });
      } else {
        setMensage({
          ...mensage,
          [e.target.name]: e.target.value,
          clientError: "",
          subjectError: "",
          mesageError: "",
        });
      }
    }
  };

  const validate = () => {
    let errorPhone = mensage.phoneError;
    let erroremail = mensage.emailError;
    let errorsubject = mensage.subjectError;
    let errormesage = mensage.mesageError;
    let errorCliente = mensage.clientError;

    if (!mensage.phone) {
      errorPhone = "Campo obligatorio";
    } else {
      if (errorPhone !== "Campo obligatorio") {
        errorPhone = mensage.phoneError;
      } else {
        errorPhone = "";
      }
    }
    if (!mensage.client) {
      errorCliente = "Campo obligatorio";
    } else {
      if (errorCliente !== "Campo obligatorio") {
        errorCliente = mensage.clientError;
      } else {
        errorCliente = "";
      }
    }
    if (!mensage.email) {
      erroremail = "Campo obligatorio";
    } else {
      if (erroremail !== "Campo obligatorio") {
        erroremail = mensage.emailError;
      } else {
        erroremail = "";
      }
    }
    if (!mensage.subject) {
      errorsubject = "Campo obligatorio";
    } else {
      if (errorsubject !== "Campo obligatorio") {
        errorsubject = mensage.subjectError;
      } else {
        errorsubject = "";
      }
    }
    if (!mensage.mesage) {
      errormesage = "Campo obligatorio";
    } else {
      if (errormesage !== "Campo obligatorio") {
        errormesage = mensage.mesageError;
      } else {
        errormesage = "";
      }
    }
    setMensage({
      ...mensage,
      phoneError: errorPhone,
      clientError: errorCliente,
      emailError: erroremail,
      subjectError: errorsubject,
      mesageError: errormesage,
    });
    if (
      errorPhone ||
      erroremail ||
      errorsubject ||
      errormesage ||
      errorCliente
    ) {
      console.table(mensage);
      return false;
    }

    return true;
  };
  const onSubmit = (e) => {
    let isValid = validate();
    if (cap) {
      if (isValid) {
        e.preventDefault();
        console.log(mensage);

        let data = {
          client: mensage.client,
          subject: mensage.subject,
          phone: mensage.phone,
          mesage: mensage.mesage,
          email: mensage.email,
        };

        axios
          .post("http://138.68.109.60:3030/send-email", data)
          .then((responce) => {
            console.log(responce);
          });
        setMensage({
          client: "",
          email: "",
          phone: "",
          subject: "",
          mesage: "",
        });
      } else {
        console.log("hay un error en el formulario");
      }
    } else {
      alert("Asegurate de que no eres un bot");
    }
  };

  const classes = useStyles();
  return (
    <Container>
      <Hidden smDown>
        <Box my={10} className={classes.image} zIndex="modal"></Box>
      </Hidden>
      <Hidden mdUp>
        <Box>
          <Grid container justify="center">
            <img
              src={require("../../assets/marca-la-matriz-radio-1.png")}
              alt="la matriz radio"
              width="45%"
            />
          </Grid>
        </Box>
      </Hidden>
      <Box mb={17}>
        <Grid container>
          <Grid item sm={12} lg={4}>
            <Box m={5} textAlign="start">
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold" my={3}>
                  CONTÁCTANOS
                </Box>
              </Typography>
              <Typography>
                Si quieres contactarte con nosotros. Aquí está toda la
                información que necesitas .
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} lg={8}>
            <Box className={classes.box}>
              <form
                onSubmit={onSubmit}
                className={classes.root}
                onChange={handleChange}
              >
                <Box mx={5} p={3}>
                  <Grid container direction="column">
                    <Grid item container spacing={10}>
                      <Grid item md={6}>
                        <Typography variant="h4">
                          <Box fontWeight="fontWeightBold" mt={3}>
                            LA MATRIZ RADIO
                          </Box>
                        </Typography>
                        <Box textAlign="start">
                          <Typography variant="h6">
                            hacemos comunidad
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box fontWeight="fontWeightBold" my={3}>
                          La Matriz Radio es una radio online de Valparaíso
                          Chile, ubicada en Santo Domingo 71, Tercer Piso.
                        </Box>
                      </Grid>
                    </Grid>
                    <Box my={3}>
                      <Grid item sm={12}>
                        <Typography variant="h4">
                          <Box fontWeight="fontWeightBold" my={3}>
                            Contacto : lamatrizradio@gmail.com
                          </Box>
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Contact;
