import React, { useContext } from "react";
import {
  CircularProgress,
  Box,
  Typography,
  Container,
  Grid,
  Hidden,
} from "@material-ui/core";
import { ShowGrid } from "../resources/ShowGrid";
import { Context } from "../../Context/Context";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles({
  image: {
    height: "20px",
  },
  programs: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
  },
});

const Programs = () => {
  const { programs, loadingPrograms } = useContext(Context);
  const path = "/programas/";
  const classes = useStyles();
  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Programas de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Hidden smDown>
        <Box my={5} className={classes.image} zIndex="modal"></Box>;
      </Hidden>

      <Hidden smUp>
        <Grid container justify="center">
          <img
            src={require("../../assets/marca-la-matriz-radio-1.png")}
            alt="la matriz radio"
            width="55%"
          />
        </Grid>
      </Hidden>

      <Box className={classes.programs}>
        <Box pb={10}>
          <Grid container justify="center" direction="column">
            <Grid item>
              <Container>
                <Typography variant="h4">
                  <Box
                    textAlign="left"
                    my={5}
                    paddingLeft={5}
                    fontWeight="fontWeightBold"
                  >
                    Programas
                  </Box>
                </Typography>
                {loadingPrograms ? (
                  <CircularProgress />
                ) : programs.length > 0 ? (
                  <ShowGrid data={programs} path={path} />
                ) : (
                  <Container>
                    <Box textAlign="left" mt={7} fontWeight="fontWeightBold">
                      <Chip size="medium" label="PRONTO" color="primary" />
                    </Box>
                  </Container>
                )}
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Programs;
