import React from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    height: "100%",
    background: "rgba(255, 165, 0, 0)",
    color:"white",
    boxShadow: "none",
  },
  avatar: {
    backgroundColor: "red",
  },
  img:{
    borderRadius:"25px",
    boxShadow:"0 3px 10px 0 #00000029"
  },

});


function MeCardTop({data}) {
  const classes = useStyles();
  return (
    <>
      <Box>
        <Card className={classes.root}>
          <Grid container justify="center" direction="column">
            <Link to={`eventos/${data.id}`}>
              <CardMedia
                component="img"
                image={process.env.REACT_APP_STRAPI_URL + data.imagen.url}
                height="200"
                alt={"La Matriz Radio"}
                className={classes.img}
              />
            </Link>
            <Grid item>
              <CardContent m={5} className={classes.cardContent}>
                <Typography component="div">
                  <Box
                    fontSize="100%"
                    textAlign="center"
                    fontWeight="fontWeightBold"
                    m={1}
                  >
                    {data.fecha}
                  </Box>
                </Typography>
                <Typography component="div">
                  <Box
                    fontSize="100%"
                    textAlign="center"
                    fontWeight="fontWeightBold"
                    m={1}
                  >
                    {data.nombre}
                  </Box>
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
}

export default MeCardTop;
