import React, { useState, useEffect } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import { Box, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import AdjustIcon from "@material-ui/icons/Adjust";
import clsx from "clsx";
import api from "../../api/api";

const useStyles = makeStyles({
  container: {
    width: "100%",
    bottom: 0,
  },
  player: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "#fff",
    height: "50px",
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  element: {
    animation: "$pulse 5s infinite",
  },
  "@keyframes pulse": {
    "0% ": {
      color: "#ffffff",
    },
    "100%": {
      color: " #5534BE",
    },
  },
});
export const Player = () => {
  const classes = useStyles();
  const [caster, setCaster] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    api
      .getCaster()
      .then((response) => {
        if (isSubscribed) {
          setCaster(response.data);

        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (isSubscribed = false);
  }, []);

  return (
    <div>
      <Hidden xsDown>
        <Box zIndex="tooltip" position="fixed" className={classes.container}>
          <Box>
            <AudioPlayer
              layout="horizontal-reverse"
              className={classes.player}
              showFilledProgress={false}
              showSkipControls={false}
              showJumpControls={false}
              autoPlayAfterSrcChange={false}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              customAdditionalControls={[]}
              customControlsSection={[
                <Box mr={1} className={clsx(classes.element)}>
                  <AdjustIcon></AdjustIcon>
                </Box>,
                <Box mb={0.5}>En vivo</Box>,
                RHAP_UI.ADDITIONAL_CONTROLS,
                RHAP_UI.MAIN_CONTROLS,
                RHAP_UI.VOLUME_CONTROLS,
              ]}
              src={caster.linkCaster}
              customIcons={{
                play: (
                  <Box mt={-0.1}>
                    {" "}
                    <PlayArrowIcon style={{ fontSize: 30 }} />{" "}
                  </Box>
                ),
                pause: <PauseIcon style={{ fontSize: 30 }} />,
              }}
            />
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box zIndex="tooltip" position="fixed" className={classes.container}>
          <Box>
            <AudioPlayer
              layout="horizontal-reverse"
              className={classes.player}
              showFilledProgress={false}
              showSkipControls={false}
              showJumpControls={false}
              autoPlayAfterSrcChange={false}
              customProgressBarSection={[]}
              customAdditionalControls={[]}
              CustomVolumeControls={[<Box>{RHAP_UI.VOLUME}</Box>]}
              customControlsSection={[
                <Box mr={1} className={clsx(classes.element)}>
                  <AdjustIcon></AdjustIcon>
                </Box>,
                <Box mb={0.5}>En vivo</Box>,
                RHAP_UI.MAIN_CONTROLS,
                RHAP_UI.VOLUME_CONTROLS,
              ]}
              src={caster.linkCaster}
              customIcons={{
                play: (
                  <Box mt={-0.1}>
                    {" "}
                    <PlayArrowIcon style={{ fontSize: 30 }} />{" "}
                  </Box>
                ),
                pause: <PauseIcon style={{ fontSize: 30 }} />,
              }}
            />
          </Box>
        </Box>
      </Hidden>
    </div>
  );
};
