import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import MeCard from "./MeCard";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 2, //soluciòn para el scroll horizontal provocado por el spacing
  },
  gridContainer: {
    height: "auto",
    width:"auto"
  },
}));

export const ShowGrid = ({ data, path}) => {
  const classes = useStyles();
  return (

    <div className={classes.root}>
      <Grid
        container
        className={classes.gridContainer}
        spacing={5}
      >
        {data.map((r,i) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
            <MeCard key={r.id} data={r} path={path}></MeCard>
          </Grid>
        ))}
      </Grid>
    </div>


  );
};
