import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import MeCardWe from "./MeCardWe";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20, //soluciòn para el scroll horizontal provocado por el spacing
  },
  gridContainer: {
    height: "auto",
    width: "auto",
  },
}));

export const ShowGridWe = () => {
  const classes = useStyles();
  let contenido = [
    {
      titulo: "MISIÓN ",
      descripcion:
        "Poner la comunicación al servicio de la comunidad a través de la radiodifusión, para fortalecer y contribuir a la superación de la desconexión, exclusión social y estigmas asociados al Barrio Puerto y Valparaíso a través de la difusión de su riqueza humana, patrimonial y cultural.",
      imagenUrl: require("../../assets/icono1.png"),
    },
    {
      titulo: "VISIÓN ",
      descripcion:
        "Ser el principal medio de Comunicación, portavoz y vitrina de los vecinos, asociaciones y actores sociales de Valparaíso, posicionándonos como una emisora activa y participativa en temas sociales, contingentes y relevantes de la V Región",
      imagenUrl: require("../../assets/icono-2.png"),
    },
    {
      titulo: "OBJETIVOS ",
      descripcion:
      " Tener una parrilla programática integradora, diversa y contingente. \n  Generara espacios de formato radial para la comunidad .\n  Generar contenido y emitir por diversas plataformas",
      imagenUrl: require("../../assets/icono3.png"),
    },
  ];

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.gridContainer}
        spacing={5}
        justify="center"
      >
        {contenido.map((r,i) => (
          <MeCardWe data={r} key={i} ></MeCardWe>
        ))}
      </Grid>
    </div>
  );
};
