import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Box, Hidden, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  icon: {
    color: "#412BB3",
    borderRadius:50,
    boxShadow: " inset 0 3px 40px #ffffff",
  },
});
export const SideBar = () => {
  const classes = useStyles();
  return (
    <div>
      <Hidden smDown>
        <Box
          zIndex="tooltip"
          position="fixed"
          ml="95%"
          mt="18%"
          style={{
            textShadow: "inset 0 5px 100px #000000",
          }}
        >
          <Box>
            <IconButton
              href="https://www.facebook.com/La-Matriz-Radio-2147581975527960/"
              target="_blank"
              rel="noopener"
              className={classes.icon}
            >
              <FacebookIcon style={{ fontSize: 35, color: "#412BB3" }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              href="https://twitter.com/LaMatrizRadio"
              target="_blank"
              rel="noopener"
              className={classes.icon}
            >
              <TwitterIcon style={{ fontSize: 35, color: "#412BB3" }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              href="https://www.youtube.com/channel/UCdKcdF3x23geD-w_9dmyAnw"
              target="_blank"
              rel="noopener"
              className={classes.icon}
            >
              <YouTubeIcon style={{ fontSize: 35, color: "#412BB3" }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              href="https://www.instagram.com/lamatrizradio/"
              target="_blank"
              rel="noopener"
              className={classes.icon}
            >
              <InstagramIcon style={{ fontSize: 35, color: "#412BB3" }} />
            </IconButton>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
};
