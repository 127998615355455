import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import api from "../../api/api";
import { ShowGrid } from "../resources/ShowGrid";
import { ShowGridWe } from "../resources/showGridWe";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  image: {
    height: "400px",
    background: "#BABABA",
  },
  imageDesktop: {
    objectFit: "cover",
    objectPosition: "100% 45%",

    width: "100%",
    height: "400px",
  },
  padre: {
    padding: 20,
  },
  galeria: {
    background: "#292929",
    color: "white",
  },
});

const WeAre = () => {
  const classes = useStyles();
  const [loading, setloading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    api
      .getMembers()
      .then((response) => {
        if (isSubscribed) {
          setData(response.data);
          setloading(false);
        }
      })
      .catch((error) => {
      });
    return () => (isSubscribed = false);
  }, []);

  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="secciónn somos de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Box zIndex="modal">
        <img
          className={classes.imageDesktop}
          alt="la Matriz Radio"
          src={require("../../assets/port02.jpg")}
        />
      </Box>
      <Box className={classes.root} zIndex="tooltip" mt="-4.9%" mb={5}>
        <Grid container spacing={5} justify="center">
          {loading ? <CircularProgress /> : <ShowGridWe />}
        </Grid>
      </Box>
      <Box py={5} className={classes.galeria}>
        <Container>
          <Typography variant="h3">
            <Box
              my={3}
              fontSize="70%"
              textAlign="left"
              fontWeight="fontWeightBold"
            >
              Nuestro Equipo
            </Box>
          </Typography>
          {loading ? <CircularProgress /> : <ShowGrid data={data} />}
        </Container>
      </Box>
    </div>
  );
};

export default WeAre;
