import React from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: "100%",
    background: "rgba(255, 165, 0, 0)",
    boxShadow: "none",
  },
  avatar: {
    backgroundColor: "red",
  },
  img:{
    height:"50%",
    width:"30%",
    marginLeft:"auto",
    marginRight:"auto",
    textShadow:"0 3px 30px 0 black"
  },
  text:{
    textAlign:"justify"
  }

});


function MeCardWe({data}) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card className={classes.root}>
          <Grid container justify="center" direction="column">
            <CardMedia
              component="img"
              alt={"La Matriz Radio"}
              image={data.imagenUrl}
              className={classes.img}
            />

            <Grid item>
              <CardContent m={5}>
                <Typography component="div">
                  <Box
                    fontSize="90%"
                    textAlign="center"
                    fontWeight="fontWeightBold"
                    m={1}
                  >
                    {data.titulo}
                  </Box>
                  <Box
                    fontSize="80%"
                    textAlign="flex-start"
                    m={1}
                    className={classes.text}
                  >
                    {data.descripcion}
                  </Box>
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default MeCardWe;
