import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  IconButton,
  Button,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import CodeIcon from "@material-ui/icons/Code";

const useStyles = makeStyles({
  footer: {
    height: "25%",
    background: "#292929",
    color: "white",
  },
  list: {
    listStyleType: "none",
  },
  twitter: {
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
    borderRadius: "25px",
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <div>
      <Hidden smDown>
        <Box>
          <Box className={classes.footer} pt="1%" pb="8%">
            <Container>
              <Grid container justify="center">
                <Grid item md={3}>
                  <Box p={1} textAlign="start">
                    <img
                      src={require("../../assets/marca-la-matriz-radio-blanco.png")}
                      alt="la matriz radio"
                      width="95%"
                    />
                  </Box>
                  <Box pb={5} textAlign="center">
                    <Typography>
                      Comparte y síguenos en nuestras redes sociales
                    </Typography>
                  </Box>
                  <Grid container justify="center">
                    <Box>
                      <IconButton
                        href="https://www.facebook.com/La-Matriz-Radio-2147581975527960/"
                        target="_blank"
                        rel="noopener"
                      >
                        <FacebookIcon
                          style={{ fontSize: 30, color: "white" }}
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton
                        href="https://twitter.com/LaMatrizRadio"
                        target="_blank"
                        rel="noopener"
                      >
                        <TwitterIcon style={{ fontSize: 30, color: "white" }} />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton
                        href="https://www.youtube.com/channel/UCdKcdF3x23geD-w_9dmyAnw"
                        target="_blank"
                        rel="noopener"
                      >
                        <YouTubeIcon style={{ fontSize: 30, color: "white" }} />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton
                        href="https://www.instagram.com/lamatrizradio/"
                        target="_blank"
                        rel="noopener"
                      >
                        <InstagramIcon
                          style={{ fontSize: 30, color: "white" }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Box my={3} ml={5} pr={5} textAlign="center">
                    <Button
                      size="large"
                      className={classes.twitter}
                      href="/desarrollo"
                      target="_blank"
                      rel="noopener"
                    >
                      <CodeIcon fontSize="large"></CodeIcon>
                      <Box mx={1}>Información del Desarrollo </Box>
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Box py={5} textAlign="center">
                    <Typography variant="h5">Contenido</Typography>
                  </Box>
                  <Box ml={8} mt={-2}>
                    <ul className={classes.list}>
                      <Box textAlign="flex-start">
                        <Link component={NavLink} to="/" color="inherit">
                          Inicio
                        </Link>
                      </Box>
                      <Box textAlign="flex-start">
                        <Link
                          component={NavLink}
                          to="/noticias"
                          color="inherit"
                        >
                          Noticias
                        </Link>
                      </Box>
                      <Box textAlign="flex-start">
                        <Link
                          component={NavLink}
                          to="programas"
                          color="inherit"
                        >
                          Programas
                        </Link>
                      </Box>
                      <Box textAlign="flex-start">
                        <Link component={NavLink} to="/somos" color="inherit">
                          Somos
                        </Link>
                      </Box>
                      <Box textAlign="flex-start">
                        <Link
                          component={NavLink}
                          to="/contacto"
                          color="inherit"
                        >
                          Contacto
                        </Link>
                      </Box>
                    </ul>
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Box p={5} textAlign="center">
                    <Typography variant="h5">Contacto</Typography>
                  </Box>
                  <ul className={classes.list}>
                    <Box textAlign="center">
                      <Link>lamatrizradio@gmail.com</Link>
                    </Box>
                  </ul>
                  <Box pl={5} textAlign="center">
                    <Typography>Valparaíso Chile</Typography>
                    <Typography>Santo Domingo 71, Tercer Piso.</Typography>
                  </Box>
                </Grid>

                <Grid item container direction="row" md={3}>
                  <Grid container item justify="center">
                    <Box textAlign="center">
                      <img
                        src={require("../../assets/cropped-logo512-14.png")}
                        alt="la matriz radio"
                        width="40%"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box textAlign="center" my={3} ml={5}>
                      <img
                        src={require("../../assets/lgb.png")}
                        alt="la matriz radio"
                        width="170%"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
};

export default Footer;
