import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/resources/Navbar";
import { AppRouter } from "./routers/AppRouters";
import {
  Box,
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
} from "@material-ui/core";
import Footer from "./components/resources/Footer";
import { SideBar } from "./components/resources/SideBar";
import ScrollToTop from "./routers/ScrollTop";
import { Context } from "./Context/Context";
import api from "./api/api";
import { Player } from "./components/resources/Player";

function App() {
  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [loadingPrograms, setLoadingPrograms] = useState(true);
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [adds, setAdd] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(true);
  useEffect(() => {
    let isSubscribed = true;
    console.log('paso por acá', process.env.REACT_APP_POSTS_DATA)
    api
      .getData()
      .then((response) => {
        if (isSubscribed) {
          setData(response.data.reverse());
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getPrograms()
      .then((response) => {
        if (isSubscribed) {
          setPrograms(response.data);
          setLoadingPrograms(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    api
      .getEvents()
      .then((response) => {
        if (isSubscribed) {
          setEvents(response.data);
          setLoadingEvents(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    api
      .getAdds()
      .then((response) => {
        if (isSubscribed) {
          setAdd(response.data);
          setLoadingAdd(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => (isSubscribed = false);
  }, []);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Context.Provider
          value={{
            data,
            loading,
            programs,
            loadingPrograms,
            events,
            loadingEvents,
            adds,
            loadingAdd,
          }}
        >
          <Router>
            <ScrollToTop></ScrollToTop>
            <Navbar></Navbar>
            <SideBar></SideBar>
            <Player></Player>
            <Box>
              <AppRouter></AppRouter>
            </Box>
            <Footer />
          </Router>
        </Context.Provider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
