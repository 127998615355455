import {
  Box,
  Grid,
  Card,
  Typography,
  CircularProgress,
  Button,
  Hidden,
  Container,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Context } from "../../Context/Context";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import EventIcon from "@material-ui/icons/Event";
import ReactMarkdown from "react-markdown";
import NewsRel from "./NewsRel";
import Slider from "react-slick";
import ProgramPhoto from "../resources/ProgramPhoto";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  text: {
    justifyContent: "left",
    textAlign: "justify",
  },
  face: {
    background: "#1778F2",
    color: "white",
    borderRadius: "25px",
  },
  twitter: {
    background: "#1DA1F2",
    color: "white",
    borderRadius: "25px",
  },
  fake: {
    height: "50px",
  },
  margin: {
    margin: theme.spacing(1),
    background:
      "transparent linear-gradient(254deg, #432CBC 0%, #5534BE 100%) ",
    color: "white",
    borderRadius: "25px",
  },
  galeria: {
    background: "#292929",
    color: "white",
  },
}));

const New = () => {
  const { data, loading } = useContext(Context);
  const { noticiaId } = useParams();
  const [categoria, setCategoria] = useState(false);
  const noticia = data.find((noticia) => noticia.id === noticiaId);

  const classes = useStyles();
  const url = window.location.href;

  const ajustarGaleria = (largo, settings) => {
    if (largo < 3) {
      settings.slidesToShow = 1;
    }
    return settings;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  if (!loading && !noticia) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="Noticia de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
      />
      <Hidden smDown>
        <Box className={classes.fake} zIndex="modal"></Box>
      </Hidden>
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box mb={10} mt={10}>
            <Container>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                spacing={5}
              >
                <Grid
                  item
                  className={classes.text}
                  xs={12}
                  sm={8}
                  md={8}
                  lg={10}
                >
                  <Typography variant="h4">
                    <Box fontWeight="fontWeightBold">{noticia.titulo}</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={5}>
                  <Card>
                    <CardMedia
                      className={classes.image}
                      component="img"
                      image={
                        process.env.REACT_APP_STRAPI_URL + noticia.imagen.url
                      }
                    />
                  </Card>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12}>
                  <Grid item container direction="row" spacing={2}>
                    <Grid item>
                      <EventIcon></EventIcon>
                    </Grid>
                    <Grid item>
                      <Typography component={"span"}>
                        <Box fontWeight="fontWeightBold">{noticia.fecha}</Box>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.text}>
                    <ReactMarkdown source={noticia.contenido} />
                  </Grid>
                  <Box my={5}>
                    <Grid item container spacing={2}>
                      <Grid item>
                        <Box>
                          <FacebookShareButton size={32} url={url}>
                            <Button size="large" className={classes.face}>
                              <FacebookIcon></FacebookIcon>
                              <Box mx={1}>compartir</Box>
                            </Button>
                          </FacebookShareButton>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          <TwitterShareButton
                            size={32}
                            url={url}
                            className={classes.twitter}
                          >
                            <Button size="large" className={classes.twitter}>
                              <TwitterIcon></TwitterIcon>
                              <Box mx={1}>compartir</Box>
                            </Button>
                          </TwitterShareButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {noticia.fotos.length === 0 ? (
              <div></div>
            ) : (
              <>
                <Hidden smDown>
                  <Box className={classes.galeria}>
                    <Box p={5} mx="10%">
                      <Typography variant="h4">
                        <Box
                          fontWeight="fontWeightBold"
                          className={classes.text}
                        >
                          {"Galería"}
                        </Box>
                      </Typography>
                      <Slider
                        {...ajustarGaleria(noticia.fotos.length, settings)}
                      >
                        {noticia.fotos.map((foto, i) => (
                          <ProgramPhoto foto={foto} key={i} />
                        ))}
                      </Slider>
                    </Box>
                  </Box>
                </Hidden>

                <Hidden mdUp>
                  <Box className={classes.galeria}>
                    <Box>
                      <Typography variant="h4">
                        <Box
                          fontWeight="fontWeightBold"
                          p={3}
                          className={classes.text}
                        >
                          {"Galería"}
                        </Box>
                      </Typography>
                      <Slider {...settings}>
                        {noticia.fotos.map((foto, i) => (
                          <ProgramPhoto foto={foto} key={i} />
                        ))}
                      </Slider>
                    </Box>
                  </Box>
                </Hidden>
              </>
            )}

            <Container>
              {noticia.categorias.length === 0 ? (
                <div>{}</div>
              ) : (
                <Typography variant="h5">
                  <Box my={3} fontWeight="fontWeightBold">
                    {"Noticias Relacionadas"}
                  </Box>
                </Typography>
              )}

              {noticia.categorias.map((cat, i) => (
                <Button
                  className={classes.margin}
                  variant="contained"
                  color="primary"
                  size="small"
                  key={i}
                  onClick={() => setCategoria(cat)}
                >
                  {cat.nombre}
                </Button>
              ))}
              {categoria ? (
                <NewsRel cat={categoria} idNew={noticia.id}></NewsRel>
              ) : (
                <div>{}</div>
              )}
            </Container>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default New;
