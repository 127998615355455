import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import MeCardTop from "./MeCardTop";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 2, //soluciòn para el scroll horizontal provocado por el spacing
  },
  gridContainer: {
    height: "auto",
    width: "auto",
    flexGrow: 1
  },
}));

export const ShowGridTop = ({ data }) => {
  const classes = useStyles();
  return (

    
    <div className={classes.root}>
      <Box mx={1} my={3}>
        <Grid container className={classes.gridContainer} spacing={5}>
          {data.map((r, i) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
            <MeCardTop key={r.id} data={r}></MeCardTop>
          </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};
