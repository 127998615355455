import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress , Box} from "@material-ui/core";
import { ShowGrid } from "../resources/ShowGrid";

const NewsRel = ( { cat, idNew} ) => {
    const [categoria, setCategoria] = useState(false);

    useEffect(() => {
      let isSubscribed = true;
      axios
        .get(`${process.env.REACT_APP_CATEGORIES_DATA}/${cat.id}`)
        .then((response) => {
          if (isSubscribed) {
            setCategoria(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      return () => (isSubscribed = false);
    }, [cat]);

    return (
      <Box my={5}>
        <meta name="robots" content="noindex, follow" />
        <meta
          name="description"
          content="Noticias relacionadas de La Matriz Radio, es el portal web de una radio de Valparaiso ubicada en Chile, es un radio chilena del barrio puerto de Valparaiso"
        />
        {categoria ? (
          <ShowGrid
            data={categoria.noticias.filter((a) => a.id !== idNew)}
            path={"/noticias/"}
          >
            {" "}
          </ShowGrid>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
}

export default NewsRel
