import axios from "axios";

export default {
  getData: () => axios.get(process.env.REACT_APP_POSTS_DATA),
  getPrograms: () => axios.get(process.env.REACT_APP_PROGRAMS_DATA),
  getMembers: () => axios.get(process.env.REACT_APP_MEMBERS_DATA),
  getEvents: () => axios.get(process.env.REACT_APP_EVENTS_DATA),
  getAdds: () => axios.get(process.env.REACT_APP_ADD_DATA),
  getCaster: () => axios.get(process.env.REACT_APP_ADD_CONF),
};
