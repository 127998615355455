import React from 'react'
import {
    Switch,
    Route
  } from 'react-router-dom';
import  News  from '../components/pages/News';
import  Programs  from '../components/pages/Programs';
import Inicio from '../components/pages/Inicio';
import WeAre from '../components/pages/WeAre';
import Contact from '../components/pages/Contact';
import New from "../components/pages/New";
import Program from '../components/pages/Program';
import Desarrollo from "../components/pages/Desarrollo";
import Event from "../components/pages/Event";

export const AppRouter = () => {
    return (
      <div>
        <Switch>
          <Route exact path="/noticias" component={News} />
          <Route exact path="/noticias/:noticiaId" component={New} />
          <Route exact path="/programas/:programaId" component={Program} />
          <Route exact path="/programas" component={Programs} />
          <Route exact path="/somos" component={WeAre} />
          <Route exact path="/contacto" component={Contact} />
          <Route exact path="/desarrollo" component={Desarrollo} />
          <Route exact path="/eventos/:eventId" component={Event} />
          <Route path="/" component={Inicio} />
        </Switch>
      </div>
    );
}
